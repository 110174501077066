"use client";
import { useCallback, useEffect, useRef } from "react";
import { State, stateToPostProduct } from "../../../app/(navbar)/listings/create/reducerState";
import { PostInventoryProduct } from "../Product";
import { AspectsResponse } from "../useSpecs";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../redux/store";
import { setPlatformActive, setPlatformInactive } from "../../../redux/features/extension-marketplace-slice";

const randomId = () => Math.floor(Math.random() * Math.pow(2, 32)).toString(16);

const integratedRequest = (id: string) => ({
  source: "SELLRAZE_WEB_REQ",
  payload: {
    message: "GET:FBM_CREDENTIALS",
  },
  messageId: id,
});

const aspectsRequest = (state: State, categoryId: string, id: string) => {
  const payload = {
    message: "GET:FBM_CATEGORY_INFO",
    categories: {
      category_type_array: [
        {
          category_id: [categoryId],
          category_name: "",
          marketplace: "FACEBOOK",
        },
      ],
    },
    item: stateToPostProduct(state),
  };
  return {
    source: "SELLRAZE_WEB_REQ",
    payload,
    messageId: id,
  };
};

const metadataRequest = (id: string) => ({
  source: "SELLRAZE_WEB_REQ",
  payload: {
    message: "GET:FBM_METADATA",
  },
  messageId: id,
});

const imageRequest = (state: State, id: string) => ({
  source: "SELLRAZE_WEB_REQ",
  payload: {
    message: "POST:FBM_IMAGE_UPLOAD",
    images: {
      image_urls: state.images.map((i) => ({
        image_url: i.url,
        selected: i.enabled,
      })),
    },
  },
  messageId: id,
});

const delistRequest = (id: string, listingId: string) => ({
  source: "SELLRAZE_WEB_REQ",
  payload: {
    message: "POST:FBM_DELIST_LISTING",
    listing_id: listingId,
  },
  messageId: id,
});

const postRequest = (
  state: State,
  id: string,
  { marketplaceId, latitude, longitude }: Metadata,
  imageIds: string[],
) => ({
  source: "SELLRAZE_WEB_REQ",
  payload: {
    message: "POST:FBM_CREATE_LISTING",
    metadata: {
      marketplace_id: marketplaceId,
      latitude,
      longitude,
      photo_ids: imageIds,
    },
    item: stateToPostProduct(state),
  },
  messageId: id,
});

const postRequestOneClick = (
  state: PostInventoryProduct,
  id: string,
  { marketplaceId, latitude, longitude }: Metadata,
  imageIds: string[],
) => ({
  source: "SELLRAZE_WEB_REQ",
  payload: {
    message: "POST:FBM_CREATE_LISTING",
    metadata: {
      marketplace_id: marketplaceId,
      latitude,
      longitude,
      photo_ids: imageIds,
    },
    item: state,
  },
  messageId: id,
});

type Response =
  | {
      type: "success";
      message: "GET:FBM_CATEGORY_INFO";
      payload: AspectsResponse;
      messageId: string;
      source: "SELLRAZE_WEB_RES";
    }
  | {
      type: "success";
      message: "GET:FBM_METADATA";
      payload: {
        data: {
          viewer: {
            marketplace_settings: {
              current_marketplace: {
                id: string;
              };
              sell_location: {
                latitude: number;
                longitude: number;
              };
            };
          };
        };
      };
      messageId: string;
      source: "SELLRAZE_WEB_RES";
    }
  | {
      type: "success";
      message: "POST:FBM_IMAGE_UPLOAD";
      payload: {
        imagesIds: { id: string }[];
      };
      messageId: string;
      source: "SELLRAZE_WEB_RES";
    }
  | {
      type: "success";
      message: "POST:FBM_DELIST_LISTING";
      messageId: string;
      source: "SELLRAZE_WEB_RES";
    }
  | {
      type: "success";
      message: "POST:FBM_CREATE_LISTING";
      messageId: string;
      source: "SELLRAZE_WEB_RES";
      payload: any;
    }
  | {
      type: "success";
      message: "GET:FBM_CREDENTIALS";
      payload: {
        id: string;
      };
      messageId: string;
      source: "SELLRAZE_WEB_RES";
    }
  | {
      type: "failed";
      message: "";
      payload: {
        type: string;
        message: string;
        code: string;
      };
      messageId: string;
      source: "SELLRAZE_WEB_RES";
    };

type OutboundMessage = {
  source: "SELLRAZE_WEB_REQ";
};

type ErrorResponse = {
  type: "failed";
  payload: {
    message: string;
  };
};

type Metadata = {
  longitude: number;
  latitude: number;
  marketplaceId: string;
};

const channel = new BroadcastChannel("SELLRAZE_WEB_WORKER");

export const useFacebook = () => {
  const handlers = useRef<Record<string, (data: Response) => void>>({});
  // add error handler
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const handler = (evt: MessageEvent<Response | OutboundMessage>) => {
      if (evt.data.source === "SELLRAZE_WEB_REQ") {
        return;
      }
      const h = handlers.current[evt.data.messageId];
      delete handlers.current[evt.data.messageId];
      h?.(evt.data);
    };
    channel.addEventListener("message", handler);
    return () => {
      channel.removeEventListener("message", handler);
    };
  }, []);

  const timeout = useCallback((name: string, id: string, rej: (reason?: unknown) => void, duration: number = 10000) => {
    setTimeout(() => {
      delete handlers.current[id];
      rej(
        JSON.stringify({
          message: "No response while calling " + name,
          code: "82c8fb33-e450-4326-9d66-3a483b623fc6",
          type: "failed",
        }),
      );
    }, duration);
  }, []);

  const getIntegrated = useCallback((): Promise<boolean> => {
    const id = randomId();

    return new Promise((res, rej) => {
      handlers.current[id] = (data) => {
        if (data.type === "failed" && data.payload) {
          dispatch(setPlatformInactive("FACEBOOK"));
          rej(JSON.stringify(data.payload));
        } else if (data.message !== "GET:FBM_CREDENTIALS") {
          dispatch(setPlatformInactive("FACEBOOK"));
          rej("Error getting Facebook credentials");
        } else {
          dispatch(setPlatformActive("FACEBOOK"));
          res(!!data.payload.id);
        }
      };

      channel?.postMessage(integratedRequest(id));

      setTimeout(() => {
        delete handlers.current[id];
        res(false);
      }, 5000);
      // timeout("getting Facebook integration status", id, rej, 5000);
    });
  }, [channel]);

  const getAspects = useCallback(
    (state: State, categoryId: string): Promise<AspectsResponse> => {
      const id = randomId();

      return new Promise((res, rej) => {
        handlers.current[id] = (data) => {
          if (data.type === "failed" && data.payload) {
            rej(JSON.stringify(data.payload));
          } else if (data.message !== "GET:FBM_CATEGORY_INFO") rej("Wrong response type on get facebook aspects");
          else res(data.payload);
        };

        channel?.postMessage(aspectsRequest(state, categoryId, id));
        timeout("get facebook aspects", id, rej);
      });
    },
    [channel],
  );

  const getMetadata = useCallback((): Promise<Metadata> => {
    const id = randomId();

    return new Promise((res, rej) => {
      handlers.current[id] = (data) => {
        if (data.type === "failed" && data.payload) rej(JSON.stringify(data.payload));
        else if (data.message !== "GET:FBM_METADATA") rej("Wrong response type on get facebook metadata");
        else
          res({
            longitude: data.payload.data.viewer.marketplace_settings.sell_location.longitude,
            latitude: data.payload.data.viewer.marketplace_settings.sell_location.latitude,
            marketplaceId: data.payload.data.viewer.marketplace_settings.current_marketplace.id,
          });
      };

      channel?.postMessage(metadataRequest(id));
      timeout("get facebook metadata", id, rej);
    });
  }, [channel]);

  const getImageIds = useCallback(
    (state: State): Promise<string[]> => {
      const id = randomId();

      return new Promise((res, rej) => {
        handlers.current[id] = (data) => {
          if (data.type === "failed" && data.payload) rej(JSON.stringify(data.payload));
          else if (data.message !== "POST:FBM_IMAGE_UPLOAD") rej("Wrong response type on get facebook image ids");
          else res(data.payload.imagesIds.map((i) => i.id));
        };
        channel?.postMessage(imageRequest(state, id));
        timeout("get facebook image ids", id, rej);
      });
    },
    [channel],
  );

  const delistListing = useCallback(
    async (listingId: string): Promise<boolean> => {
      const id = randomId();

      return new Promise((res, rej) => {
        handlers.current[id] = (data) => {
          if (data.type === "failed" && data.payload) rej(JSON.stringify(data.payload));
          else if (data.message !== "POST:FBM_DELIST_LISTING") rej("Wrong response type on delist facebook listing");
          else res(true);
        };
        channel?.postMessage(delistRequest(id, listingId));
        timeout("delist facebook listing", id, rej);
      });
    },
    [channel],
  );

  const postListing = useCallback(
    async (state: State): Promise<any> => {
      const id = randomId();
      const [metadata, imageIds] = await Promise.all([getMetadata(), getImageIds(state)]);

      return new Promise((res, rej) => {
        handlers.current[id] = (data) => {
          if (data.type === "failed" && data.payload) rej(JSON.stringify(data.payload));
          else if (data.message !== "POST:FBM_CREATE_LISTING") rej("Wrong response type on post facebook listing");
          else res(data.payload);
        };
        channel?.postMessage(postRequest(state, id, metadata, imageIds));
        timeout("post facebook listing", id, rej, 1000 * 20);
      });
    },
    [getMetadata, getImageIds, channel],
  );

  return {
    getIntegrated,
    getAspects,
    delistListing,
    postListing,
  };
};
