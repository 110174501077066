import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Marketplace, marketplaceInfo } from "../../lib/platforms";
import { defaultsDeep } from "lodash";
import { State } from "../../app/(navbar)/listings/create/reducerState";
import { Mode } from "../../app/(navbar)/listings/create/reducer";
import { updateState } from "../../app/(navbar)/listings/create/reducerState";
type InitialState = {
  value: State;
};

const createInitialState = (mode: Mode = "draft"): State => ({
  version: 5,
  listingId: "",
  inventoryId: "",
  platforms: [],
  general: {
    hasVariations: false,
    categories: [],
    imported: false,
    itemName: "",
    brandName: "",
    externalId: "",
    externalIdType: "",
    modelNumber: "",
    sku: "",
    placeholderSku: crypto.randomUUID?.() ?? "",
    condition: "",
    description: "",
    notes: "",
    itemPurchaseDate: "",
    quantity: "",
  },
  images: [],
  variants: [],
  specs: [],
  itemDimensions: {
    length: {
      value: "",
      unit: "",
    },
    width: {
      value: "",
      unit: "",
    },
    height: {
      value: "",
      unit: "",
    },
    weight: {
      value: "",
      unit: "",
    },
  },
  packageDimensions: {
    length: {
      value: "",
      unit: "",
    },
    width: {
      value: "",
      unit: "",
    },
    height: {
      value: "",
      unit: "",
    },
    weight: {
      value: "",
      unit: "",
    },
  },
  pricing: {
    platforms: [],
    quantity: "",
    itemCost: "",
    defaultListingPrice: "",
    currency: "USD",
    amazonFBA: false,
  },
  mode: mode,
});

const initialState = {
  value: createInitialState() as State,
} as InitialState;

export const inventory = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setPlatform: (state, action: PayloadAction<{ enabled: boolean; platform: string }>) => {
      const { enabled, platform } = action.payload;

      if (enabled) {
        state.value.platforms.push({
          marketplace: platform as Marketplace,
          marketplaceIds: [marketplaceInfo[platform as keyof typeof marketplaceInfo].defaultId],
        });
      } else {
        state.value.platforms = state.value.platforms.filter((p) => p.marketplace !== platform);
      }

      storeLocal(state.value);
      return state;
    },
    setCategoryPath: (state, action: PayloadAction<{ platform: string; path: string; name: string }>) => {
      const { platform, path, name } = action.payload;
      const category = state.value.general.categories.find((c) => c.marketplace === platform);

      if (category !== undefined) {
        category.categoryPath = JSON.parse(path);
        category.categoryName = name;
      } else {
        state.value.general.categories.push({
          marketplace: platform as Marketplace,
          categoryPath: JSON.parse(path),
          categoryName: name,
        });
      }

      const duplicates = state.value.general.categories.filter((c) => c.marketplace === platform);
      if (duplicates.length > 1) {
        const seen = new Set();
        state.value.general.categories = state.value.general.categories.filter((c) => {
          if (seen.has(c.categoryPath)) {
            return false;
          } else {
            seen.add(c.categoryPath);
            return true;
          }
        });
      }

      storeLocal(state.value);
      return state;
    },
    setVariantType: (state, action: PayloadAction<{ enabled: boolean; variantType: string }>) => {
      const { enabled, variantType } = action.payload;

      if (enabled) {
        const index = state.value.variants.findIndex((v) => v.type === variantType);
        if (index === -1) {
          state.value.variants.push({
            type: JSON.parse(variantType),
            values: [],
          });
        }
      } else {
        state.value.variants = state.value.variants.filter((v) => v.type !== variantType);
      }
      storeLocal(state.value);
      return state;
    },
    addVariant: (state, action: PayloadAction<{ variantType: string; value: string }>) => {
      const { variantType, value } = action.payload;
      const variant = state.value.variants.find((v) => v.type === variantType);

      if (variant !== undefined) {
        variant.values = [...variant.values, value];
      }
      storeLocal(state.value);
      return state;
    },
    updateVariant: (state, action: PayloadAction<{ variantType: string; index: number; value: string }>) => {
      const { variantType, index, value } = action.payload;
      const variant = state.value.variants.find((v) => v.type === variantType);

      if (variant !== undefined) {
        variant.values[index] = value;
      }
      storeLocal(state.value);
      return state;
    },
    removeVariant: (state, action: PayloadAction<{ variantType: string; index: number }>) => {
      const { variantType, index } = action.payload;
      const variant = state.value.variants.find((v) => v.type === variantType);

      if (variant !== undefined) {
        variant.values.splice(index, 1);
      }
      storeLocal(state.value);
      return state;
    },
    setDimensionValue: (state, action: PayloadAction<{ described: string; dimension: string; value: string }>) => {
      const { described, dimension, value } = action.payload;
      (state as any).value[`${described}Dimensions`][dimension].value = value;
      storeLocal(state.value);
      return state;
    },
    setDimensionUnit: (state, action: PayloadAction<{ dimensionType: string; described: string; unit: string }>) => {
      const { dimensionType, described, unit } = action.payload;

      if (dimensionType === "length") {
        (state as any).value[`${described}Dimensions`].length.unit = unit;
        (state as any).value[`${described}Dimensions`].width.unit = unit;
        (state as any).value[`${described}Dimensions`].height.unit = unit;
      } else {
        (state as any).value[`${described}Dimensions`].weight.unit = unit;
      }
      storeLocal(state.value);
      return state;
    },
    setGeneralProperty: (state, action: PayloadAction<{ property: string; value: any }>) => {
      const { property, value } = action.payload;
      if (property === "quantity") {
        (state as any).value.pricing.quantity = value;
      } else {
        (state as any).value.general[property] = value;
      }
      storeLocal(state.value);
      return state;
    },
    setVariations: (state, action: PayloadAction<boolean>) => {
      state.value.general.hasVariations = action.payload;
      storeLocal(state.value);
      return state;
    },
    setListingPrice: (state, action: PayloadAction<{ marketplace: Marketplace; value: string }>) => {
      const { marketplace, value } = action.payload;
      let platform = state.value.pricing.platforms.find((p) => p.marketplace === marketplace);

      if (platform === undefined) {
        platform = {
          marketplace: marketplace,
          listingPrice: "",
          platformFees: "",
          defaultPlatformFees: "",
          shipping: [],
        };
        state.value.pricing.platforms.push(platform);
      }

      platform.listingPrice = value;
      storeLocal(state.value);
      return state;
    },
    setPlatformFees: (state, action: PayloadAction<{ marketplace: Marketplace; value: string }>) => {
      const { marketplace, value } = action.payload;
      let platform = state.value.pricing.platforms.find((p) => p.marketplace === marketplace);

      if (platform === undefined) {
        platform = {
          marketplace,
          listingPrice: "",
          platformFees: "",
          defaultPlatformFees: "",
          shipping: [],
        };
        state.value.pricing.platforms.push(platform);
      }

      platform.platformFees = value;
      storeLocal(state.value);
      return state;
    },
    setDefaultPlatformFees: (state, action: PayloadAction<{ marketplace: Marketplace; value: string }>) => {
      const { marketplace, value } = action.payload;
      let platform = state.value.pricing.platforms.find((p) => p.marketplace === marketplace);

      if (platform === undefined) {
        platform = {
          marketplace,
          listingPrice: "",
          platformFees: "",
          defaultPlatformFees: "",
          shipping: [],
        };
        state.value.pricing.platforms.push(platform);
      }

      platform.defaultPlatformFees = value;
      storeLocal(state.value);
      return state;
    },
    setLogistics: (state, action: PayloadAction<{ property: string; value: any }>) => {
      const { property, value } = action.payload;
      (state as any).value.pricing[property] = value;
      storeLocal(state.value);
      return state;
    },
    setFBA: (state, action: PayloadAction<boolean>) => {
      state.value.pricing.amazonFBA = action.payload;
      storeLocal(state.value);
      return state;
    },
    addImages: (state, action: PayloadAction<string[]>) => {
      state.value.images.push(
        ...action.payload.map((url) => ({
          url,
          enabled: true,
        })),
      );
      storeLocal(state.value);
      return state;
    },
    reorderImages: (
      state,
      action: PayloadAction<{
        newArr: {
          url: string;
          enabled: boolean;
        }[];
      }>,
    ) => {
      const { newArr } = action.payload;
      // get image to be moved
      state.value.images = newArr;
      storeLocal(state.value);
      return state;
    },
    setImage: (state, action: PayloadAction<{ index: number; enabled: boolean }>) => {
      const { index, enabled } = action.payload;
      state.value.images[index].enabled = enabled;
      storeLocal(state.value);
      return state;
    },
    removeImage: (state, action: PayloadAction<number>) => {
      state.value.images.splice(action.payload, 1);
      storeLocal(state.value);
      return state;
    },
    addSpec: (
      state,
      action: PayloadAction<{ marketplace: Marketplace; name: string; defaultValue: any; required?: boolean }>,
    ) => {
      const { marketplace, name, defaultValue, required } = action.payload;
      let marketplaceVar = state.value.specs.find((s) => s.marketplace === marketplace);

      if (marketplaceVar === undefined) {
        marketplaceVar = { marketplace, specs: {}, dirtySpecs: new Set() };
        state.value.specs.push(marketplaceVar);
      }

      const defaultFormValue = typeof defaultValue === "object" ? "" : defaultValue?.toString() ?? "";

      if (defaultValue !== "") {
        marketplaceVar.dirtySpecs.add(name);
      }

      if (marketplaceVar.specs[name] === undefined) {
        marketplaceVar.specs[name] = { formValue: defaultFormValue, value: defaultValue, required: required ?? false };
      } else {
        defaultsDeep(marketplaceVar.specs[name].value, defaultValue);
        marketplaceVar.specs[name].formValue ||= defaultFormValue;
        marketplaceVar.specs[name].value ||= defaultValue;
        marketplaceVar.specs[name].required = required;
      }

      storeLocal(state.value);
      return state;
    },
    setSpec: (
      state,
      action: PayloadAction<{ marketplace: string; name: string | string[]; value: any; formValue: string }>,
    ) => {
      let { marketplace, name, value, formValue } = action.payload;

      if (typeof name === "string") {
        name = [name];
      }

      const [head, ...tail] = name;

      const marketplaceItem = state.value.specs.find((s) => s.marketplace === marketplace);

      if (marketplaceItem === undefined) {
        return state;
      }

      marketplaceItem.dirtySpecs.add(head);
      const spec = marketplaceItem.specs[head];

      const obj = tail.reduce((acc, curr) => acc.value[curr], spec);

      obj.value = value;
      obj.formValue = formValue;

      storeLocal(state.value);
      return state;
    },
    deleteSpec: (state, action: PayloadAction<{ marketplace: Marketplace; name: string }>) => {
      const { marketplace, name } = action.payload;

      let marketplaceItem = state.value.specs.find((s) => s.marketplace === marketplace);

      if (marketplaceItem === undefined) {
        marketplaceItem = { marketplace, specs: {}, dirtySpecs: new Set() };
        state.value.specs.push(marketplaceItem);
      }

      delete marketplaceItem.specs[name];
      storeLocal(state.value);
      return state;
    },
    readLocal: (state, action: PayloadAction<{ mode: string }>) => {
      const mode = action.payload.mode as Mode;
      state.value = updateState(getLocal(mode));
      return state;
    },
    resetLocal: (state, action: PayloadAction<{ mode: Mode }>) => {
      const { mode } = action.payload;
      storeLocal(createInitialState(mode));
      return initialState;
    },
    addPricing: (
      state,
      action: PayloadAction<{
        marketplace?: Marketplace;
        id: string;
        group: string;
        minPrice: number;
        maxPrice: number;
        zipCode?: string;
        shippingTime?: string;
        carrierName?: string;
        displayName?: string;
      }>,
    ) => {
      const { marketplace, id, group, minPrice, maxPrice, zipCode, shippingTime, carrierName, displayName } =
        action.payload;

      let platform = state.value.pricing.platforms.find((p) => p.marketplace === marketplace);

      if (platform === undefined) {
        platform = {
          listingPrice: "",
          platformFees: "",
          defaultPlatformFees: "",
          shipping: [],
          marketplace: "AMAZON",
        };
        state.value.pricing.platforms.push(platform);
      }

      // if (!platform.shipping.some(s => s.id === id)) {
      //     platform.shipping.push({
      //         id,
      //         rateMinimum: minPrice,
      //         rateMaximum: maxPrice,
      //         carrierName: carrierName ? carrierName : '',
      //         displayName: displayName ? displayName : '',
      //         shippingTime: shippingTime ? shippingTime : '',
      //         pricingUnit: 'USD',
      //         zipCode: zipCode ? zipCode : '',
      //     });
      // }

      storeLocal(state.value);
      return state;
    },
    removePricing: (state, action: PayloadAction<{ marketplace: Marketplace; id: string }>) => {
      const { marketplace, id } = action.payload;

      let platform = state.value.pricing.platforms.find((p) => p.marketplace === marketplace);
      if (platform === undefined) {
        platform = {
          marketplace,
          listingPrice: "",
          platformFees: "",
          defaultPlatformFees: "",
          shipping: [],
        };
        state.value.pricing.platforms.push(platform);
      } else {
        platform.shipping = platform.shipping.filter((s) => s.id !== id);
      }

      storeLocal(state.value);
      return state;
    },
    modifyPricing: (
      state,
      action: PayloadAction<{
        marketplace: Marketplace;
        id: string;
        group: string;
        minPrice: number;
        maxPrice: number;
      }>,
    ) => {
      const { marketplace, id, group, minPrice, maxPrice } = action.payload;

      let platform = state.value.pricing.platforms.find((p) => p.marketplace === marketplace);
      if (platform === undefined) {
        return state;
      }

      let method = platform.shipping.find((s) => s.id === id);
      if (method === undefined) {
        return state;
      }

      method.id = id;
      method.rateMaximum = minPrice;
      method.rateMaximum = maxPrice;

      return state;
    },
    addShippingMethod: (
      state,
      action: PayloadAction<{
        marketplace: Marketplace;
        id: string;
        rateMin: number;
        rateMax: number;
        carrierName?: string;
        displayName?: string;
        shippingTime?: string;
        pricingUnit?: string;
        zipCode?: string;
      }>,
    ) => {
      const { marketplace, id, rateMin, rateMax, carrierName, displayName, shippingTime, pricingUnit, zipCode } =
        action.payload;

      let platform = state.value.pricing.platforms.find((p) => p.marketplace === marketplace);

      if (platform === undefined) {
        platform = {
          marketplace,
          listingPrice: "",
          platformFees: "",
          defaultPlatformFees: "",
          shipping: [],
        };
        state.value.pricing.platforms.push(platform);
      }

      platform.shipping.push({
        id: id,
        rateMinimum: rateMin,
        rateMaximum: rateMax,
        carrierName: carrierName ? carrierName : "",
        displayName: displayName ? displayName : "",
        shippingTime: shippingTime ? shippingTime : "",
        pricingUnit: pricingUnit ? pricingUnit : "",
        zipCode: zipCode ? zipCode : "",
      });
      storeLocal(state.value);
      return state;
    },
    removeShippingMethod: (state, action: PayloadAction<{ marketplace: Marketplace; id: string }>) => {
      const { marketplace, id } = action.payload;

      let platform = state.value.pricing.platforms.find((p) => p.marketplace === marketplace);
      if (platform === undefined) {
        return state;
      } else {
        platform.shipping = platform.shipping.filter((s) => s.id !== id);
      }

      storeLocal(state.value);
      return state;
    },
    setInventory: (state, action: PayloadAction<State>) => {
      storeLocal(action.payload);
      return {
        value: action.payload,
      };
    },
    setListingId: (state, action: PayloadAction<string>) => {
      state.value.listingId = action.payload;

      storeLocal(state.value);
      return state;
    },
    setInventoryId: (state, action: PayloadAction<string>) => {
      state.value.inventoryId = action.payload;

      storeLocal(state.value);
      return state;
    },

    getInventory: (state) => {
      return state;
    },
    resetInventory: () => {
      return initialState;
    },
  },
});

const storeLocal = async (s: State) => {
  try {
    localStorage.setItem(
      `listing-storage-${s.mode}`,
      JSON.stringify(s, (key, value) => (key === "dirtySpecs" ? Array.from(value) : value)),
    );
  } catch (e) {
    console.error("Failed to store draft in localStorage: ", e);
  }
};

const getLocal = (mode: Mode) => {
  try {
    const json = localStorage.getItem(`listing-storage-${mode}`);
    if (json !== null && json !== "") {
      return JSON.parse(json, (key, value) => (key === "dirtySpecs" ? new Set(value) : value));
    }
  } catch (e) {
    console.error("Failed to retrieve draft from localStorage: ", e);
  }
  return createInitialState(mode);
};

export const {
  setPlatform,
  setCategoryPath,
  setVariantType,
  addVariant,
  updateVariant,
  removeVariant,
  setDimensionValue,
  setDimensionUnit,
  setGeneralProperty,
  setVariations,
  setListingPrice,
  setPlatformFees,
  setDefaultPlatformFees,
  setLogistics,
  setFBA,
  addImages,
  reorderImages,
  setImage,
  removeImage,
  addSpec,
  setSpec,
  deleteSpec,
  readLocal,
  resetLocal,
  addPricing,
  removePricing,
  modifyPricing,
  addShippingMethod,
  removeShippingMethod,
  setInventory,
  setListingId,
  setInventoryId,
  getInventory,
  resetInventory,
} = inventory.actions;
export default inventory.reducer;
